import React, { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { getVCard } from "../API/fetch";
import LOGO from "../assets/images/logo.png";
import FULL_LOGO from "../assets/images/Logo.svg";
import {
  Layout,
  Avatar,
  Typography,
  Divider,
  Row,
  Space,
  Button,
  Modal,
  Card,
  Col,
} from "antd";
import {
  EditOutlined,
  SettingOutlined,
  TwitterOutlined,
  InstagramOutlined,
  PhoneOutlined,
  NumberOutlined,
  MailOutlined,
  GlobalOutlined,
  FacebookFilled,
  LinkedinFilled,
  LoadingOutlined,
  DownloadOutlined,
  ShareAltOutlined,
  WhatsAppOutlined,
  MessageOutlined,
  CopyOutlined,
} from "@ant-design/icons";

import { cardUrl, photoUrl } from "../API/config";
import { FaSnapchatSquare } from "react-icons/fa";
import { DataContext } from "../context";
import { banfsag } from "../styles/colors";
const { Meta } = Card;

const { Footer } = Layout;
const { Text } = Typography;

const Info = () => {
  const { id } = useParams();
  const history = useHistory();
  const savedUser = useContext(DataContext).user;
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isOwner, setIsOwner] = useState(null);

  useEffect(() => {
    if (savedUser?.slug === id) {
      setIsOwner(true);
    } else {
      setIsOwner(false);
    }
  }, [id, savedUser]);

  useEffect(() => {
    async function getData(id) {
      try {
        const res = await getVCard(id);
        if (res.data?.user) {
          if (res.data?.user?.mode === "SnapChat" && isOwner === false) {
            window.location.href = `https://snapchat.com/add/${res.data?.user?.snapchat}`;
            setLoading(false);
          } else if (
            res.data?.user?.mode === "Instagram" &&
            isOwner === false
          ) {
            window.location.href = `https://www.instagram.com/${res.data?.user?.instagram}`;
            setLoading(false);
          } else if (res.data?.user?.mode === "Linkedin" && isOwner === false) {
            window.location.href = `https://linkedIn.com/in/${res.data?.user?.linkedIn}`;
            setLoading(false);
          } else if (res.data?.user?.mode === "Twitter" && isOwner === false) {
            window.location.href = `https://twitter.com/${res.data?.user?.twitter}`;
            setLoading(false);
          } else if (res.data?.user?.mode === "Facebook" && isOwner === false) {
            window.location.href = `https://facebook.com/${res.data?.user?.facebook}`;
            setLoading(false);
          } else if (res.data?.user?.mode === "vcf" && isOwner === false) {
            window.location.href = `${cardUrl}/${res.data?.user?.slug}.vcf`;
            setLoading(false);
          } else {
            setUser(res.data.user);
            setLoading(false);
          }
        }
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    }
    if (id) {
      getData(id);
    }
  }, [id, isOwner]);

  const handleSave = () => {
    window.location.href = `${cardUrl}/${user?.slug}.vcf`;
  };

  const [isModalVisible, setIsModalVisible] = useState(false);
  const showModal = () => {
    setIsModalVisible(true);
  };
  const handleOk = () => {
    setIsModalVisible(false);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const shareWhatsApp = () => {
    let url = `https://contact.rushd.com/info/${user?.slug}`;
    let text = `هذه صفحتي الشخصية 
    ${url}`;
    window.location.href = `https://api.whatsapp.com/send?text=${text}`;
  };

  if (loading) {
    return (
      <Layout
        style={{
          minHeight: "100vh",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#fafafa",
        }}
      >
        <LoadingOutlined style={{ fontSize: 100, color: "#212121" }} />
      </Layout>
    );
  }
  return (
    <Layout
      style={{
        minHeight: "100vh",
        alignItems: "center",
        width: "100%",
        backgroundColor: "#f1f1f1",
      }}
    >
      <Modal
        centered
        title={"Share your card"}
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText='Done'
        okButtonProps={{
          style: {
            backgroundColor: "#212121",
            color: "#fafafa",
            borderColor: "#212121",
            borderRadius: "5px",
            paddingRight: "30px",
            paddingLeft: "30px",
          },
        }}
        cancelText='Cancel'
      >
        <Row style={{ width: "100%" }} justify='space-between' align='middle'>
          <Col
            style={{
              justifyContent: "center",
              alignItem: "center",
              width: "100%",
              display: "flex",
            }}
            span={8}
          >
            <WhatsAppOutlined
              onClick={shareWhatsApp}
              style={{ fontSize: 30 }}
            />
          </Col>

          <Col
            style={{
              justifyContent: "center",
              alignItem: "center",
              width: "100%",
              display: "flex",
            }}
            span={8}
          >
            <a
              style={{ color: "#212121" }}
              href={`sms:&body=${`This is my personal card
    https://contact.rushd.com/info/${user?.slug}`}`}
            >
              <MessageOutlined style={{ fontSize: 30 }} />
            </a>
          </Col>

          <Col
            style={{
              justifyContent: "center",
              alignItem: "center",
              width: "100%",
              display: "flex",
            }}
            span={8}
          >
            <Text
              copyable={{
                text: `https://contact.rushd.com/info/${user?.slug}`,
                icon: (
                  <CopyOutlined style={{ fontSize: 30, color: "#212121" }} />
                ),
              }}
            ></Text>
          </Col>
        </Row>
      </Modal>

      {/* </Header> */}
      <Layout style={{ backgroundColor: "#f1f1f1", zIndex: 1 }}>
        <Card
          style={{
            width: window.innerWidth > 500 ? 400 : "85vw",
            marginTop: 30,
            marginBottom: "auto",
            backgroundColor: "#ffffff",
            borderRadius: 20,
            overflow: "hidden",
          }}
          cover={
            user?.bg ? (
              <img alt='example' src={`${photoUrl}/${user.bg}`} />
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: banfsag,
                }}
              >
                <img
                  alt='example'
                  src={FULL_LOGO}
                  style={{
                    width: 200,
                  }}
                />
              </div>
            )
          }
          actions={
            isOwner
              ? [
                  <SettingOutlined
                    key='setting'
                    onClick={() => history.push("/profile")}
                  />,
                  <EditOutlined
                    key='edit'
                    onClick={() => history.push("/edit")}
                  />,
                  <ShareAltOutlined key={"share"} onClick={showModal} />,
                ]
              : [<ShareAltOutlined key={"share-guste"} onClick={showModal} />]
          }
        >
          <Meta
            avatar={
              <Avatar src={user.photo ? `${photoUrl}/${user.photo}` : LOGO} />
            }
            title={`${user?.firstName} ${
              user?.middleName ? user?.middleName : ""
            } ${user?.lastName ? user?.lastName : ""}`}
            description={
              <Space
                style={{ marginTop: 0, marginBottom: 15 }}
                direction='horizontal'
              >
                <Text type='secondary'>
                  {user?.title ? user?.title + " - " : ""}
                </Text>
                <Text type='secondary'>{user?.organization}</Text>
              </Space>
            }
          />
          <Row
            flex={1}
            justify='start'
            align='middle'
            style={{ paddingLeft: 10, paddingRight: 10, marginBottom: 10 }}
          >
            <PhoneOutlined style={{ fontSize: 24 }} />
            <Divider type='vertical' />
            <Space size={0} direction='vertical' align='start'>
              <Text style={{ marginBottom: 0 }} type='secondary'>
                Phone:
              </Text>
              <Text
                style={{
                  color: banfsag,
                }}
              >
                {user?.cellPhone}
              </Text>
            </Space>
          </Row>
          {user?.workPhone ? (
            <Row
              flex={1}
              justify='start'
              align='middle'
              style={{ paddingLeft: 10, paddingRight: 10, marginBottom: 10 }}
            >
              <NumberOutlined style={{ fontSize: 24 }} />
              <Divider type='vertical' />
              <Space size={0} direction='vertical' align='start'>
                <Text style={{ marginBottom: 0 }} type='secondary'>
                  Work Phone:
                </Text>
                <Text
                  style={{
                    color: banfsag,
                  }}
                >
                  {user?.workPhone}
                </Text>
              </Space>
            </Row>
          ) : null}
          {user?.email ? (
            <Row
              flex={1}
              justify='start'
              align='middle'
              style={{ paddingLeft: 10, paddingRight: 10, marginBottom: 10 }}
            >
              <MailOutlined style={{ fontSize: 24 }} />
              <Divider type='vertical' />
              <Space size={0} direction='vertical' align='start'>
                <Text style={{ marginBottom: 0 }} type='secondary'>
                  Email:
                </Text>
                <a
                  href={`mailto: ${user.email}`}
                  rel='noreferrer'
                  target='_blank'
                  style={{
                    color: banfsag,
                  }}
                >
                  {user?.email}
                </a>
              </Space>
            </Row>
          ) : null}
          {user?.url ? (
            <Row
              flex={1}
              justify='start'
              align='middle'
              style={{ paddingLeft: 10, paddingRight: 10, marginBottom: 10 }}
            >
              <GlobalOutlined style={{ fontSize: 24 }} />
              <Divider type='vertical' />

              <Space size={0} direction='vertical' align='start'>
                <Text style={{ marginBottom: 0 }} type='secondary'>
                  Website:
                </Text>
                <a
                  href={`${user.url}`}
                  rel='noreferrer'
                  target='_blank'
                  style={{
                    color: banfsag,
                  }}
                >
                  {user?.url}
                </a>
              </Space>
            </Row>
          ) : null}
          <Divider />
          <Row
            flex={1}
            justify='space-evenly'
            align='middle'
            style={{ width: "100%", flexWrap: "wrap" }}
          >
            {user?.twitter ? (
              <Col
                span={8}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom: 20,
                }}
              >
                <TwitterOutlined style={{ fontSize: 24, color: banfsag }} />
                <Text
                  onClick={() =>
                    (window.location.href = `https://twitter.com/${user.twitter}`)
                  }
                  type='secondary'
                >
                  {user?.twitter}
                </Text>
              </Col>
            ) : null}
            {user?.instagram ? (
              <Col
                span={8}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom: 20,
                }}
              >
                <InstagramOutlined style={{ fontSize: 24, color: banfsag }} />
                <Text
                  onClick={() =>
                    (window.location.href = `https://instgram.com/${user.instagram}`)
                  }
                  type='secondary'
                >
                  {user?.instagram}
                </Text>
              </Col>
            ) : null}
            {user?.facebook ? (
              <Col
                span={8}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom: 20,
                }}
              >
                <FacebookFilled style={{ fontSize: 24, color: banfsag }} />
                <Text
                  onClick={() =>
                    (window.location.href = `https://facebook.com/${user.facebook}`)
                  }
                  type='secondary'
                >
                  {user?.facebook}
                </Text>
              </Col>
            ) : null}
            {user?.snapchat ? (
              <Col
                span={8}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom: 20,
                }}
              >
                <FaSnapchatSquare size={24} color={banfsag} />
                <Text
                  onClick={() =>
                    (window.location.href = `https://snapchat.com/add/${user.snapchat}`)
                  }
                  type='secondary'
                >
                  {user?.snapchat}
                </Text>
              </Col>
            ) : null}
            {user?.linkedIn ? (
              <Col
                span={8}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom: 20,
                }}
              >
                <LinkedinFilled style={{ fontSize: 24, color: banfsag }} />
                <Text
                  onClick={() =>
                    (window.location.href = `https://linkedIn.com/in/${user.linkedIn}`)
                  }
                  type='secondary'
                >
                  {user?.linkedIn}
                </Text>
              </Col>
            ) : null}
          </Row>
          <Row style={{ width: "100%" }} justify='center'>
            <Button
              type='primary'
              icon={<DownloadOutlined />}
              size={"large"}
              loading={false}
              onClick={handleSave}
              style={{
                marginBottom: 10,
                borderRadius: 10,
                backgroundColor: banfsag,
                borderColor: banfsag,
              }}
            >
              Save Card
            </Button>
          </Row>
        </Card>
      </Layout>
      <Footer
        flex={1}
        justify='center'
        align='middle'
        style={{
          backgroundColor: banfsag,
          width: "98vw",
          marginTop: 20,
        }}
      >
        <Text
          onClick={() => {
            window.location.href = "https://contact.rushd.com";
          }}
          type='secondary'
          style={{ fontSize: 12, color: "#fafafa" }}
        >
          All rights reserved © {new Date().getFullYear()} Rushd Business Cards
        </Text>
      </Footer>
    </Layout>
  );
};

export default Info;
