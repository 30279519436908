import React, { useContext, useState } from "react";
import { Form, Input, Select, Button, Typography, Image, Card } from "antd";
// import { UploadOutlined } from "@ant-design/icons";
import CountriesCode from "./CountriesCode.json";
import logo from "../../assets/images/Logo.svg";
import { useHistory } from "react-router-dom";
import { banfsag, light, gold } from "../../styles/colors";
import { postLogin } from "../../API/fetch";
import { DataContext } from "../../context";
import { Footer } from "antd/lib/layout/layout";

const { Text, Title } = Typography;

const { Option } = Select;

const Login = () => {
  const [form] = Form.useForm();
  const history = useHistory();
  const { setUser } = useContext(DataContext);
  const [loading, setLoading] = useState(false);
  const onFinish = async (values) => {
    const data = {
      credentials: {
        ...values,
      },
    };
    try {
      setLoading(true);
      const res = await postLogin(data);

      setUser(res.data.user);
      history.push(`/info/${res.data.user.slug}`);
      setLoading(false);
    } catch (error) {
      console.log(error.response.data);
      setLoading(false);
      if (
        error.response?.data?.message ===
        "The provided username or password is incorrect"
      ) {
        alert("The provided username or password is incorrect");
      } else {
        alert("Something went wrong, please try again");
      }
      console.log(error);
    }
  };

  const prefixSelector = (
    <Form.Item name='prefixCellPhone' noStyle>
      <Select
        style={{
          width: 100,
        }}
      >
        {CountriesCode.map((c) => {
          return (
            <Option key={c.code} value={c.dial_code}>
              {c.dial_code} - {c.code}
            </Option>
          );
        })}
      </Select>
    </Form.Item>
  );

  return (
    <div
      className='my-font'
      style={{
        minHeight: "100vh",
        backgroundColor: light,
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
      }}
    >
      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            paddingTop: 10,
          }}
        >
          <Image
            preview={false}
            onClick={() => history.push("/")}
            src={logo}
            style={{ maxWidth: 100, resizeMode: "contain" }}
          />
          <Title level={4} align='center' style={{ color: banfsag }}>
            Rushd Business Cards
          </Title>
        </div>
      </div>
      <div
        style={{
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
        }}
      >
        <div>
          <Card
            style={{
              width: window.innerWidth > 500 ? 400 : "90vw",
              backgroundColor: "#ffffff",
              borderRadius: 20,
              paddingTop: 20,
              paddingBottom: 20,
            }}
          >
            <Form
              // {...formItemLayout}
              form={form}
              name='register'
              onFinish={onFinish}
              style={{ marginRight: 20, marginLeft: 20, marginTop: 20 }}
              initialValues={{
                prefixCellPhone: "+966",
              }}
              scrollToFirstError
            >
              <Form.Item
                name='cellPhone'
                label='Phone'
                rules={[
                  {
                    required: true,
                    message: "Please input your phone number!",
                  },
                ]}
              >
                <Input
                  addonBefore={prefixSelector}
                  style={{
                    width: "100%",
                  }}
                />
              </Form.Item>
              <Form.Item
                name='password'
                label='Password'
                rules={[
                  {
                    required: true,
                    message: "Please input your password!",
                  },
                ]}
                hasFeedback
              >
                <Input.Password />
              </Form.Item>

              <Form.Item>
                <Button
                  shape='round'
                  style={{ backgroundColor: banfsag, color: light }}
                  htmlType='submit'
                  loading={loading}
                  disabled={loading}
                >
                  Sign in
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </div>
      </div>
      <Footer
        flex={1}
        justify='center'
        align='middle'
        style={{
          backgroundColor: banfsag,
        }}
      >
        <Text
          onClick={() => {
            window.location.href = "https://www.rushd.com";
          }}
          type='secondary'
          style={{ fontSize: 12, color: "#fafafa" }}
        >
          All rights reserved © {new Date().getFullYear()} Rushd Business Cards
        </Text>
      </Footer>
    </div>
  );
};

export default Login;
