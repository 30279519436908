import React from "react";
import { Layout, Button, Image, Typography, Card, Space } from "antd";
import { useHistory } from "react-router-dom";
import logo from "../assets/images/Logo.svg";
import { banfsag, light } from "../styles/colors";
import {
  FaExclamationCircle,
  FaGlobe,
  FaLocationArrow,
  FaPhone,
} from "react-icons/fa";
const { Header, Content, Footer } = Layout;
const { Text, Title } = Typography;

const Home = () => {
  const history = useHistory();
  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Header
        style={{
          // minHeight: "15vh",
          // maxHeight: "15vh",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          paddingLeft: 20,
          paddingRight: 20,
          backgroundColor: `${banfsag}`,
          // width: "100vw",
          // position: "relative",
        }}
      >
        <div>
          <Button
            shape='round'
            className='my-font'
            onClick={() => history.push("/login")}
            style={{
              backgroundColor: light,
              color: banfsag,
              borderColor: banfsag,
            }}
          >
            Login
          </Button>
        </div>

        <Image
          preview={false}
          src={logo}
          style={{ width: 80, height: 50, resizeMode: "contain" }}
        />
      </Header>
      <Layout style={{ backgroundColor: banfsag, zIndex: 1 }}>
        <Content
          flex={1}
          justify='center'
          align='middle'
          style={{ paddingTop: "32px", paddingRight: 10, paddingLeft: 10 }}
        >
          <Title
            level={2}
            className='my-font'
            style={{
              color: "#fafafa",
            }}
          >
            Rushd Business Cards
          </Title>

          <Content style={{ paddingRight: "32px", paddingLeft: "32px" }}>
            <Text
              className='my-font'
              style={{ fontSize: 20, color: "#fafafa" }}
            >
              Rushd NFC Business Cards, Just tap the card on your phone to save
              your contact details.
            </Text>
          </Content>
          <Content
            style={{
              width: "100vw",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Card
              style={{
                minWidth: window.innerWidth / 2,
                maxWidth: window.innerWidth > 500 ? 300 : 300,
                marginTop: "32px",
                borderRadius: 20,
                marginBottom: 30,
              }}
            >
              <div
                style={{
                  display: "flex",
                  // justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <Image
                  src={logo}
                  preview={false}
                  style={{
                    maxWidth: window.innerWidth > 500 ? 300 : 200,
                  }}
                />
                <Space
                  direction='vertical'
                  size={20}
                  style={{
                    marginTop: 20,
                    alignItems: "flex-start",
                  }}
                >
                  {/* display company contact information */}
                  <Space direction='horizontal' size={10}>
                    <FaPhone name='phone' color={banfsag} />
                    <Text
                      onClick={() => {
                        window.location.href = "tel:00966112338081";
                      }}
                      style={{
                        color: banfsag,
                        fontSize: 16,
                      }}
                    >
                      +966 11 2338081
                    </Text>
                  </Space>
                  {/* display email */}
                  <Space direction='horizontal' size={10}>
                    <FaExclamationCircle color={banfsag} />
                    <Text
                      onClick={() => {
                        window.location.href = "mailto:info@rushd.com";
                      }}
                      style={{
                        color: banfsag,
                        fontSize: 16,
                      }}
                    >
                      info@rushd.com
                    </Text>
                  </Space>
                  {/* Address */}
                  <Space direction='horizontal' size={10}>
                    <FaLocationArrow color={banfsag} />
                    <div
                      style={{
                        width: "100%",
                        textAlign: "left",
                      }}
                    >
                      <Text
                        style={{
                          color: banfsag,
                          fontSize: 16,
                          textAlign: "left",
                        }}
                      >
                        Ar Rabi, Riyadh 13315, Saudi Arabia
                      </Text>
                    </div>
                  </Space>
                  <Space direction='horizontal' size={10}>
                    <FaGlobe color={banfsag} />
                    <Text
                      onClick={() => {
                        window.location.href = "https://www.rushd.com";
                      }}
                      style={{
                        color: banfsag,
                        fontSize: 16,
                      }}
                    >
                      www.rushd.com
                    </Text>
                  </Space>
                </Space>
              </div>
            </Card>
          </Content>
        </Content>
        <Footer
          flex={1}
          justify='center'
          align='middle'
          style={{
            backgroundColor: "#A5873D",
          }}
        >
          <Text
            onClick={() => {
              window.location.href = "https://www.rushd.com";
            }}
            type='secondary'
            style={{ fontSize: 12, color: "#fafafa" }}
          >
            All rights reserved © {new Date().getFullYear()} Rushd Business
            Cards
          </Text>
        </Footer>
      </Layout>
    </Layout>
  );
};

export default Home;
