import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import Home from "./pages/Home";
import Info from "./pages/Info";
import Profile from "./pages/Profile";
import EditForm from "./pages/EditForm";
import Login from "./pages/Auth/Login";

// function toDataURL(url, callback) {
//   var xhr = new XMLHttpRequest();
//   xhr.onload = function () {
//     var reader = new FileReader();
//     reader.onloadend = function () {
//       callback(reader.result);
//     };
//     reader.readAsDataURL(xhr.response);
//   };
//   xhr.open("GET", url);
//   xhr.responseType = "blob";
//   xhr.send(); https://contact.rushd.com/info/Abdulrahman-G0XLT
// }

function App() {
  return (
    <Router>
      <Switch>
        <Route path='/' exact component={Home} />
        <Route path='/info/:id' exact component={Info} />
        <Route path='/profile/' exact component={Profile} />
        <Route path='/edit/' exact component={EditForm} />
        <Route path='/Login/' exact component={Login} />
        <Route path='*' component={Home} />
      </Switch>
    </Router>
  );
}

export default App;
